<template>
  <div id="login">
    <el-scrollbar max-height=100%>
    <h1 class="name">海南省生物医学工程学会</h1>
    <div id="subForm" >
      <div style="text-align: center;color: black;font-weight: bold;font-size: 22px;margin: 25px auto;padding-top: 10px;">缴纳入会费</div>
      <div style="font-family:STKaiti;font-size: 18px;color:rgb(19, 57, 170);">姓名：{{ userName }}</div>
        <div  style="font-family:STKaiti;font-size: 18px;color:rgb(19, 57, 170)">身份证号：{{ idNumber }}</div>
      <el-icon style="width: 100%;border: none;color: green;font-size: 100px;padding-top: 2vh"><CircleCheck /></el-icon>
      <div class="paySuccess">支付成功！！！</div>
    </div>
  </el-scrollbar>
  </div>
</template>
  
<script>
import {CircleCheck} from "@element-plus/icons-vue";
import {ref} from "vue"
 export default {
   name: "PaySuccess",
   data() {
     return {
      baseUrl:process.env.NODE_ENV === "production" ? "" : "http://cenziliweichat.natapp4.cc",
      userName:ref(),
      idNumber:ref()
     }
   },
   components: {
    CircleCheck,
        },
   mounted(){
    this.idNumber=history.state.params.id
    this.userName=history.state.params.name
    },
   methods: {
    },
}
</script>
  
<style scoped lang="scss">
.name{
    font-family: 'Heiti';
    font-size: 25px;
    color: #1C1D22;
    text-align: center;
    padding-top: 12vh;
  }
#login{
  position:relative;
  width: 100vw;
  height: 100%;
  background-image: url('../assets/641.jpg');
  background-repeat: no-repeat;
  overflow: hidden;
  background-size:cover;
  background-position: center top;

}
#subForm {
  /* position: absolute; */
  /*width: 500px;*/
  /*min-height: 300px;*/
  width: 350px;
  min-height:35vh;
  margin:1vh auto;
  top:0;
  background: rgba(255,250,240,.5);
  box-sizing:border-box;
  border-radius: 30px;
  box-shadow: 0px 15px 25px rgba(0,0,0,.5);
  opacity: 0.75;
  text-align: center;
}
.userName{
  font-family: 'Heiti';
  font-size: 15px;
  color: #1C1D22;
  text-align: center;
  padding-bottom: 2vh;
}
.el-radio {
    padding: 6px 15px 0 15px;
    padding-top: 60px;
    padding-right: 15px;
    padding-bottom: 0px;
    padding-left: 15px !important;
    border-radius: var(--el-border-radius-base);
    height: 28px;
    width: 100px; //宽度可以自定义，如果不自定义，会根据label长度和padding生成
    font-size: 160px;
  }

.userBox{
  line-height: 40px;
  font-size: 18px;

}
.el-button{
  font-size:medium
}
.paySuccess{
  font-family: SimHei;
  font-size: 25px;
  color: green;
  width: 100%;
  min-height: 100px;
  line-height: 100px;
  
}

  
  </style>