<template>
  <div class="membership-card" :style="{ backgroundImage: `url(${require('../assets/card_bg-2.jpg')})` }">
    <div class="card-content">
        <div v-if="positonLabel" style="display:block" class="nameAndPosition">
          <span  class="avatarName">{{ avatarName }}</span>
          <span class="member-position">{{ position }}</span>
        </div>
        <div v-if="!positonLabel" style="display:block" class="nameAndNullPosition">
          <span  class="avatarName">{{ avatarName }}</span>
        </div>
        <div class="card-name">{{ cardName }}</div>
        <div class="card-number">{{ cardNumber }}</div>
      </div>
    <!-- <img class="card-avatar" src="../assets/2.png" :alt="avatarName" /> -->
  </div>
</template>
  
<script>

export default {
  props: {
    cardBackground: String,
    cardNumber: String,
    cardName: String,
    avatarName: String,
    position: String,
    positonLabel:Boolean
  },
  mounted(){
  }
  
};
</script>
  
<style scoped>
.membership-card {
  position: relative;
  width: 300px;
  height: 180px;
  border-radius: 10px;
  background-size: cover;
  background-position: center;
  overflow: hidden;
}

.card-content {
  display: block;
  position: absolute;
  bottom: 10px;
  left: 20px;
  color: white;
  text-align: center;
  /* margin-top: 100px; */
}

.avatarName{
  font-family: 'SimHei';
  font-size: 22px;
  /* margin-bottom: 10px; */
  /* color: rgb(255, 251, 10); */
  /* float: left; */
  /* font-weight: bolder; */
}
.member-position {
  font-family: 'STXingkai';
  margin-left: 15px;
  font-size: 22px;
  /* color: rgba(1, 1, 1, 0.872); */
  /* font-weight: bolder; */
  /* float: right; */
  /* margin-bottom: 8px; */
}
.card-number {
  font-size: 17px;
  margin-bottom: 8px;
  margin-top: 1px;
  color: yellow;
  float: left;
}

.nameAndPosition{
  margin-bottom: 10px;
  margin-left: 50px;
}

.nameAndNullPosition{
  margin-bottom: 10px;
  margin-left: 90px;
}
</style>
  