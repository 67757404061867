<template>
  <div id="login">
    <el-scrollbar max-height=100%>
    <h1 class="name">海南省生物医学工程学会</h1>
    <div id="subForm" >
      <div style="text-align: center;color: black;font-weight: bold;font-size: 22px;margin: 25px auto;padding-top: 2vh;">缴纳入会费</div>
      <div class="userBox">
        <div v-if="payResult==0" style="font-family:STKaiti;font-size: 18px;color:rgb(19, 57, 170)">姓名：{{ userName }}</div>
        <div v-if="payResult==0" style="font-family:STKaiti;font-size: 18px;color:rgb(19, 57, 170)">身份证号：{{ idNumber }}</div>
        <div v-if="payResult==0" style="font-family:SimHei;font-size: 20px;margin-bottom:5px;min-height:60px;color:rgb(255, 10, 10)">会费: 200元/年</div>
      </div>
      <el-button v-if="payResult==0" v-bind:disabled="payButton" type="success" @click="pay" size="large" style="margin-bottom:20px">点击支付</el-button>
      <el-button v-if="payResult==0" type="info" @click="closePage"  size="large" style="margin-bottom:20px">返回公众号</el-button>
      <el-icon v-if="payResult==1" style="width: 100%;border: none;color: green;font-size: 100px;"><CircleCheck /></el-icon>
      <div v-if="payResult==1" class="paySuccess">支付成功！！！</div>
    </div>
  </el-scrollbar>
  </div>
</template>
  
<script>
import request from "../request";
import { ref } from 'vue';
import {CircleCheck} from "@element-plus/icons-vue";
 export default {
   name: "PayPage",
   data() {
     return {
      userName:ref(),
      idNumber:ref(),
      openId:ref(),
      baseUrl:process.env.NODE_ENV === "production" ? "" : "http://cenziliweichat.natapp4.cc",
      payButton:ref(false),
      payResult:ref(0)
     }
   },
   components: {
    CircleCheck,
        },
   mounted(){
     this.payButton=false
     this.openId = history.state.params.openId
     this.idNumber = history.state.params.id
     this.userName = history.state.params.name
   },
   methods: {
    onBridgeReady() {
      var params = {}
      params.description="一年会员费",
      params.price=20000,
      params.openId=this.openId,
      params.idNumber = this.idNumber
      request.post(this.baseUrl+"/pay", params,{}).then(res => {
        if(res.appId!=null){
          res.package = res.packageVal
          var  vm = this;
          WeixinJSBridge.invoke(
              'getBrandWCPayRequest', res,
        function (res) {
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            vm.payResult=1
            vm.$message({
                 type: "success",
                 message: "付款成功"
            })
            // vm.$router.push("/CardPage");
          }
          else{
            this.payButton=false
            vm.$message({
                 type: "error",
                 message: "付款失败"
                  })
                }
              }
            );
          }
        })
    },
    pay() {
      this.payButton=true
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady, false);
        } else if (document.attachEvent) {
          document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady);
          document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady);
        }
      } else {
        this.onBridgeReady();
      }
    },
    closePage() {
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener('WeixinJSBridgeReady', this.wxClosePage, false);
        } else if (document.attachEvent) {
          document.attachEvent('WeixinJSBridgeReady', this.wxClosePage);
          document.attachEvent('onWeixinJSBridgeReady', this.wxClosePage);
        }
      } else {
        this.wxClosePage();
      }
    },
    wxClosePage() {
      WeixinJSBridge.call('closeWindow');
    },
  }
}
</script>
  
<style scoped lang="scss">
.name{
    font-family: 'Heiti';
    font-size: 25px;
    color: #1C1D22;
    text-align: center;
    padding-top: 12vh;
  }
#login {
  position:relative;
  width: 100vw;
  height: 100%;
  background-image: url('../assets/641.jpg');
  background-repeat: no-repeat;
  overflow: hidden;
  background-size:cover;
  background-position: center top;

}
#subForm {
  /* position: absolute; */
  /*width: 500px;*/
  /*min-height: 300px;*/
  width: 350px;
  min-height:35vh;
  margin:1vh auto;
  top:0;
  background: rgba(255,250,240,.5);
  box-sizing:border-box;
  border-radius: 30px;
  box-shadow: 0px 15px 25px rgba(0,0,0,.5);
  opacity: 0.75;
  text-align: center;
}
.userName{
  font-family: 'Heiti';
  font-size: 15px;
  color: #1C1D22;
  text-align: center;
  padding-bottom: 2vh;
}
.el-radio {
    padding: 6px 15px 0 15px;
    padding-top: 60px;
    padding-right: 15px;
    padding-bottom: 0px;
    padding-left: 15px !important;
    border-radius: var(--el-border-radius-base);
    height: 28px;
    width: 100px; //宽度可以自定义，如果不自定义，会根据label长度和padding生成
    font-size: 160px;
  }

.userBox{
  line-height: 40px;
  font-size: 18px;

}
.el-button{
  font-size:medium
}
.paySuccess{
  font-family: SimHei;
  font-size: 25px;
  color: green;
  width: 100%;
  min-height: 100px;
  line-height: 100px;
  
}

  
  </style>