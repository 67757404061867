import { createApp } from 'vue'
import App from './App.vue'
import router from './router.js'
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css'
import '../src/assets/css/global.css'
import 'dayjs/locale/zh-cn'


const app = createApp(App)
app.use(router);
app.use(ElementPlus, {size:'small'});
app.mount('#app');