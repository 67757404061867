<template>
  <el-button type="primary" class="button" @click="clear" size="large">清除localstorage</el-button>
</template>

<script>
export default {

    methods:{
        clear(){
            window.localStorage.clear()
        }
    }
}
</script>

<style>
.button{
    margin-top: 10vh;
    margin-left: 30vw;
}
</style>