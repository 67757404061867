<template>
    <div id="login">
        <el-scrollbar max-height=100%>
            <h1 class="name">海南省生物医学工程学会</h1>
            <div id="subForm">
                <div
                    style="text-align: center;color: black;font-weight: bold;font-size: 22px;margin: 10px auto;padding-top: 2vh;">
                    审核页面
                </div>
                <div class="type">
                    <el-radio-group v-model="typeValue" size="large" @change="typeChange">
                        <el-radio-button label="未审核"></el-radio-button>
                        <el-radio-button label="已审核"></el-radio-button>
                    </el-radio-group>
                </div>
                <el-scrollbar max-height=100% style="text-align: center;">
                    <el-table :data="notExamineList" border style="width: 90%;margin: auto" v-if="typeValue == '未审核'" 
                    :header-cell-style="{'text-align':'center'}"  :cell-style="{'text-align':'center'}" height="300">
                        <el-table-column fixed prop="state" label="选择">
                                <template #default="scope"> 
                                    <el-form>
                                        <el-checkbox-group v-model="scope.row.state">                                    
                                        <el-checkbox v-for="child in scope.row.examineState" :key="child.id"
                                            text-color="#FF0000" />
                                    </el-checkbox-group>
                                </el-form>
                            </template>
                        </el-table-column>
                        <el-table-column prop="name" label="姓名" />
                        <el-table-column prop="id" label="身份证号" />
                    </el-table>
                    <el-table :data="ExamineList" border style="width: 90%;;margin: auto" v-if="typeValue == '已审核'" 
                    :header-cell-style="{'text-align':'center'}"  :cell-style="{'text-align':'center'}" height="450">
                        <el-table-column prop="name" label="姓名" />
                        <el-table-column prop="id" label="身份证号" />
                    </el-table>
                    <div style="margin-top:10px;margin-bottom:10px">
                        <el-checkbox v-if="checkAll == false && typeValue == '未审核'" v-model="checkAll" @click="handleCheckAllChange(checkAll)" >全选  
                        </el-checkbox>
                    </div>
                    <div style="margin-top:10px;margin-bottom:10px">
                        <el-checkbox v-if="checkAll == true && typeValue == '未审核'" v-model="checkAll" @click="handleCheckAllChange(checkAll)" >取消全选  
                        </el-checkbox>
                    </div>
                    <el-button type="primary" @click="submit(); refresh()" size="large" v-if="typeValue == '未审核'" style="margin-top:15px;">提交</el-button>
                    </el-scrollbar>
                    
            </div>
        </el-scrollbar>
    </div>
</template>



<script>
import { ref } from 'vue'
import request from "../request";
export default {
    name: "ExaminePage",
    data() {
        return {
            getMemberLable:true,
            checkAll: false,   
            notExamineList: ref([]),
            ExamineList: ref([]),
            baseUrl: process.env.NODE_ENV === "production" ? "" : "http://cenziliweichat.natapp4.cc",
            typeValue: '未审核',
            list: ref([]),
            subList:{
                id:[],
                result:""
            }
        }
    },
    mounted() {
        if (history.state.params.user != "admin"||history.state.params.password!="123456") {
            this.$router.push("/LoginPage")
        }
        this.type = "未审核"
        this.getAllMember()
    },
    methods: {
       
        typeChange(val) {
            if (val == "未审核") {
                this.isShow = true
            } else {
                this.isShow = false
            }
            if(this.getMemberLable){
                this.notExamineList= [],
                this.ExamineList= [],
                this.getAllMember()
            }

        },
        submit() {
            for (var i = 0; i < this.notExamineList.length; i++) {
                if (this.notExamineList[i].state[0] == false) {
                    this.list.push(this.notExamineList[i].id)
                    this.subList.result="审核成功"
                    this.subList.id=this.list
                }
            }

            if (this.list.length > 0) {
                request.post(this.baseUrl+"/member/updateStatus", 
                {ids:this.subList.id,result:this.subList.result}).then(res => {
                     
                    if(res.MSG=="操作成功"){
                        this.$message({
                            type: "success",
                            message: "提交成功"
                        })
                    }
                })
            }else{
                this.$message({
                    type: "error",
                    message: "请至少选择一个"
                })
            }
        },
        refresh(){
                window.location.reload();
            },
        getAllMember(){
            this.getMemberLable=false
            request.post(this.baseUrl+"/member/getAllMember?pw="+"ARIGOHASDF16542123RGArfWT").then(res => {
                for (var i = 0; i < res.length; i++) {
                        if (res[i].status=="未审核") {
                            this.notExamineList.push({
                                name: res[i].person_name,
                                id: res[i].idCard_number,
                                state: [],
                                examineState: [{
                                    id: 1
                                }]
                            })

                        } else {
                            this.ExamineList.push({
                                name: res[i].person_name,
                                id: res[i].idCard_number,
                                state: [],
                                examineState: [{
                                    id: 1
                                }]
                            })
                        }
                }
                this.getMemberLable=true
            })  
        },
        handleCheckAllChange(val) {
            if (val == false) {
                for (var i = 0; i < this.notExamineList.length; i++) {
                    this.notExamineList[i].state[0] = (false)
                }
            } else {
                for (var j = 0; j < this.notExamineList.length; j++) {
                    this.notExamineList[j].state.length=0
                }
            }
        },
        getCode () { //获取用户的code
      this.code = ''
      var local = window.location.href // 获取页面url
      var appid = 'wxc6124cb8ddfa917a'
      // wxc6124cb8ddfa917a
      this.code = this.getUrlCode().code // 截取code
      
      if (this.code == null || this.code === ''||this.code == undefined) { // 如果没有code，则去请求
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(local)}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`//重定向
      }else{
        this.judgeRegisterByCode(this.code)//此时已经获得到code，用code判断是否注册
      }
    },
    getUrlCode() { // 截取url中的code方法
      var url = location.search
      this.winUrl = url
      var theRequest = new Object()
      if (url.indexOf("?") != -1) {
        var str = url.substring(1)
        var strs = str.split("&")
        for (var i = 0; i < strs.length; i++) { theRequest[strs[i].split("=")[0]] = (strs[i].split("=")[1]) }
      } 
      return theRequest
    },
    }
}
</script>

<style scoped>
.name {
    font-family: 'Heiti';
    font-size: 25px;
    color: #1C1D22;
    text-align: center;
    padding-top: 5vh;
}

#login {
    position: relative;
    width: 100vw;
    height: 100%;
    background-image: url('../assets/641.jpg');
    background-repeat: no-repeat;
    overflow: hidden;
    background-size: cover;
    background-position: center top;
    min-width: 380px;
    background-attachment: fixed
}

#subForm {
    /* position: absolute; */
    /*width: 500px;*/
    /*min-height: 300px;*/
    width: 90%;
    height: 80vh;
    min-height: 55vh;
    margin: 1vh auto;
    top: 0;
    background: rgba(255, 250, 240, .5);
    box-sizing: border-box;
    border-radius: 30px;
    box-shadow: 0px 15px 25px rgba(0, 0, 0, .5);
    opacity: 0.75;
    text-align: center;
}
</style>