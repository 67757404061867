import { createRouter , createWebHashHistory } from "vue-router";
import PayPage from "./view/PayPage";
import RegisterPage from "./view/RegisterPage";
import LoginPage from "./view/LoginPage"
import CardPage from "./view/CardPage"
import ExaminePage from "./view/ExaminePage"
import PaySuccess from "./view/PaySuccess"
import LoginPageExamine from "./view/LoginPageExamine"
import ClearLocalPage from "./view/ClearLocalPage"


const router = createRouter({
    history:createWebHashHistory(),
    routes:[
        {
            path:"/",
            redirect:'/RegisterPage'
        },
        {
            path:"/RegisterPage",
            component:RegisterPage
        },
        {
            path:"/PayPage",
            name:"PayPage",
            component:PayPage
        },
        {
            path:"/PaySuccess",
            name:"PaySuccess",
            component:PaySuccess
        },
        {
            path:"/LoginPage",
            component:LoginPage
        },
        {
            path:"/CardPage",
            name:"CardPage",
            component:CardPage
        },
        {
            path:"/ExaminePage",
            name:"ExaminePage",
            component:ExaminePage
        },
        {
            path:"/LoginPageExamine",
            name:"LoginPageExamine",
            component:LoginPageExamine
            
        },
        {
            path:"/ClearLocalPage",
            name:"ClearLocalPage",
            component:ClearLocalPage
            
        }
    ]
});

export default router;