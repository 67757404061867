<template>
  <div id="login">
    <h1 class="name">海南省生物医学工程学会</h1>
    <div id="subForm" v-if="showAll">
      <div style="text-align: center;color: black;font-weight: bold;font-size: 22px;margin: 25px auto;padding-top: 2vh;">会员登录</div>
      <el-form :model="form" label-width="110px" ref="form" :rules="rules" size="large">
        <el-form-item label="身份证号" prop="idCard_number">
          <el-input style="width: 85%" placeholder="请输入账号" ref="getUser" v-model="form.idCard_number" />
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input style="width: 85%" placeholder="请输入密码" v-model="form.password" show-password />
        </el-form-item>
        <el-form-item style="margin-top: 30px;margin-left: 10px">
         
        </el-form-item>
        <el-button type="primary"  @click="login">点击登录</el-button>
        <el-button type="danger" @click="register()">无账号，跳转注册</el-button>
      </el-form>
    </div>
  </div>
</template>
  
<script>
import request from "../request";
import {ref} from 'vue';
 
 export default {
   name: "LoginTest",
   data() {
     return {
      showAll:ref(false),
      result:[],
      openId: ref(),
      baseUrl: process.env.NODE_ENV === "production" ? "" : "http://cenziliweichat.natapp4.cc",
      form: {},
      userId: ref(),
      password: ref(),
      rules: {
        idCard_number: [
          { required: true, message: '请输入身份证号', trigger: 'blur' },
          { min: 18, max: 18, message: '长度在18个字符', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 11, message: '长度在 6 到 11 个字符', trigger: 'blur' }
        ],
      },
     }
   },
   mounted(){
        if(window.localStorage.getItem('openId')){//如果缓存中的openid未定义，则清除缓存
          var firstOpenId = window.localStorage.getItem('openId')
          if(firstOpenId==undefined||firstOpenId==null||firstOpenId==''||firstOpenId=="undefined"){
            window.localStorage.clear()
          }
        }
        if(!window.localStorage.getItem('openId')){ // 如果缓存localStorage中没有微信openId，则需用code去后台获取
          this.getCode();
        }else{
          this.openId=window.localStorage.getItem('openId')
          if(window.localStorage.getItem('loginOpenId')){
              if(window.localStorage.getItem('loginOpenId')==this.openId){
                this.loginAuto()
              }
          }else{
            this.showAll=true
          }
        }
      },

   methods: {
     login() {
      this.userId = this.form.idCard_number
      this.password = this.form.password
      if(this.userId=="admin"&&this.password=="123456"){
        this.$router.push({
          name:"ExaminePage",
          state:{
            params:{
              user:this.userId,
              password:this.password,
            }
          }
      })
      }else{
       this.$refs['form'].validate((valid) => {
         if (valid) {
              this.judgeValidity()              
            }
          })
        }
    },
    loginAuto() {
      request.get(this.baseUrl+"/order/getOrderByOpenId?openId="+this.openId).then(res1 => {
        this.result = res1.result
        request.post(this.baseUrl + "/member/getMemberByOpenId", { openId: this.openId }, {}).then(res => {
        //有支付记录  判断是否满一年
        if(this.result.length>0){
          var date2 = this.result[0].date
          var m = parseInt(new Date().getTime() - new Date(date2).getTime()) / (1000 * 60 * 60 * 24)
          if (m < 365) {
            //不满一年，无需支付
            this.$router.push({
              name: "PaySuccess",
              state: {
                params: {
                  openId: this.openId,
                  id: res.userInfo[0].idCard_number,
                  name: res.userInfo[0].person_name
                }
              }
            })
          } else {
            //满一年   需要重新支付
            this.$router.push({
              name: "PayPage",
              state: {
                params: {
                  openId: this.openId,
                  id: res.userInfo[0].idCard_number,
                  name: res.userInfo[0].person_name
                }
              }
            })
          }
        }else{
          //无支付记录
          //判断注册及审核状态
          if(res.data === "YES"&&res.userInfo[0].status == "审核成功"){//已注册且审核通过  去支付
            this.$router.push({              
              name: "PayPage",
              state: {
                params: {
                  openId: this.openId,
                  id: res.userInfo[0].idCard_number,
                  name: res.userInfo[0].person_name
                }
              }
            })
          }else{
            //未注册  或   审核中  
            this.$message({
                type: "error",
                message: "账户未注册，请先注册再登录"
            })
            setTimeout(() => {
              this.closePage()
            }, 2000);
          }
        }
      })
      
      })
      
    },
    judgeValidity(){
      request.get(this.baseUrl+"/order/getOrderByOpenId?openId="+this.openId).then(res1 => {
        this.result = res1.result 
        request.post(this.baseUrl + "/member/getMemberByOpenId", { openId: this.openId }, {}).then(res => {
        if (res.data === "YES") {//已注册
          if(this.password == res.userInfo[0].password && this.userId == res.userInfo[0].idCard_number){
            window.localStorage.setItem('loginOpenId', this.openId)
              //有支付记录  判断是否满一年
              if(this.result.length>0){
                var date2 = this.result[0].date
                var m = parseInt(new Date().getTime() - new Date(date2).getTime()) / (1000 * 60 * 60 * 24)
                if (m < 365) {
                  //不满一年，无需支付
                  this.$router.push({
                    name: "PaySuccess",
                    state: {
                      params: {
                        openId: this.openId,
                        id: res.userInfo[0].idCard_number,
                        name: res.userInfo[0].person_name
                      }
                    }
                  })
                } else {
                  //满一年   需要重新支付
                  this.$router.push({
                    name: "PayPage",
                    state: {
                      params: {
                        openId: this.openId,
                        id: res.userInfo[0].idCard_number,
                        name: res.userInfo[0].person_name
                      }
                    }
                  })
                }
              }else{
                //无支付记录
                //判断注册及审核状态
                if (res.userInfo[0].status == "审核成功") {
                    this.$router.push({
                      name: "PayPage",
                      state: {
                      params: {
                        openId: this.openId,
                        id: res.userInfo[0].idCard_number,
                        name: res.userInfo[0].person_name
                      }
                    }
                    })
                }else {//未审核
                  this.$message({
                      type: "error",
                      message: "账户未审核，请联系管理员审核"
                  })
                  }
                }
          }else{//账号  密码错误
              this.$message({
                type: "error",
                message: "账号或密码不正确，请重新输入"
              })
          }
        }else{//未注册
          this.$message({
                type: "error",
                message: "账户未注册，请先注册再登录"
          })
          setTimeout(() => {
            this.closePage()
          }, 2000);
        }
      })      
      })
      
    },
    register() {
       this.$router.push("/RegisterPage")
     },
    judgeByCode(code){//通过code查询改用是否已经注册，注册则跳转支付页
      request.post(this.baseUrl+"/member/getMemberByCode", {code:code},{}).then(res => {
        window.localStorage.setItem('openId',res.openId)
        this.openId=res.openId
        if(window.localStorage.getItem('loginOpenId')){
              if(window.localStorage.getItem('loginOpenId')==this.openId){
                this.loginAuto()
              }else{
                this.showAll=true
              }
        }else{
          this.showAll=true
        }
      })
    },
    getCode(){ //获取用户的code
      this.code = ''
      var local = window.location.href // 获取页面url
      var appid = 'wxc6124cb8ddfa917a'
      // wxc6124cb8ddfa917a
      this.code = this.getUrlCode().code // 截取code
      if (this.code == null || this.code === ''||this.code == undefined) { // 如果没有code，则去请求
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(local)}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`//重定向
      }else{
        this.judgeByCode(this.code)//此时已经获得到code，用code判断是否注册
      }
    },
    getUrlCode() { // 截取url中的code方法
      var url = location.search
      this.winUrl = url
      var theRequest = new Object()
      if (url.indexOf("?") != -1) {
        var str = url.substring(1)
        var strs = str.split("&")
        for (var i = 0; i < strs.length; i++) { theRequest[strs[i].split("=")[0]] = (strs[i].split("=")[1]) }
      } 
      return theRequest
    },  
    closePage() {
        if (typeof WeixinJSBridge == "undefined") {
          if (document.addEventListener) {
            document.addEventListener('WeixinJSBridgeReady', this.wxClosePage, false);
          } else if (document.attachEvent) {
            document.attachEvent('WeixinJSBridgeReady', this.wxClosePage);
            document.attachEvent('onWeixinJSBridgeReady', this.wxClosePage);
          }
        } else {
          this.wxClosePage();
        }
      },
      wxClosePage() {
        WeixinJSBridge.call('closeWindow');
      },
}}
</script>
  
<style scoped lang="scss">
.name{
    font-family: 'Heiti';
    font-size: 25px;
    color: #1C1D22;
    text-align: center;
    padding-top: 12vh;
  }
#login {
  position:relative;
  width: 100vw;
  height: 100%;
  background-image: url('../assets/641.jpg');
  background-repeat: no-repeat;
  overflow: hidden;
  background-size:cover;
  background-position: center top;

}
#subForm {
  /* position: absolute; */
  /*width: 500px;*/
  /*min-height: 300px;*/
  width: 350px;
  min-height:45vh;
  margin:1vh auto;
  top:0;
  background: rgba(255,250,240,.5);
  box-sizing:border-box;
  border-radius: 30px;
  box-shadow: 0px 15px 25px rgba(0,0,0,.5);
  opacity: 0.75;
  text-align: center;
}
.login{
  font-family: 'Heiti';
  font-size: 15px;
  color: #0e0aea;
  text-align: center;
  padding-top: 5vh;
  padding-bottom: 5vh;
  text-decoration:underline
}
  
  </style>