<template>
    <div id="memberInfo" v-if="MemberPageShow&&!recordShow">
    <div id="subForm">
        <div class="title">会员信息</div>
      <el-descriptions :column="1" size="large" border>
        <el-descriptions-item label="姓名" align="center">{{ name }}</el-descriptions-item>
        <el-descriptions-item label="工作单位" align="center">{{ work_place }}</el-descriptions-item>
        <el-descriptions-item label="有效期至" :span="2" align="center">{{ validDate }}</el-descriptions-item>
        <el-descriptions-item label="职位" align="center" >
          <el-tag type="success" align="center">{{ position }}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="单位地址" align="center">{{ address }}
        </el-descriptions-item>
      </el-descriptions>
      <el-button type="danger" @click="changeMemberShow" size="large" style="margin-top:15px;margin-bottom:15px">返回</el-button>
    </div>
    
  </div>
    <div class="VipPageContent" v-if="!MemberPageShow&&!recordShow">
        <div class="MembershipCard">
            <MembershipCard cardBackground="src\assets\card_bg.png" :cardNumber="cardNumber" cardName=""
            :avatarName="name" :position="position" :positonLabel="positionLabel"/>
        </div>
        <div class="card-points">
            <!-- <span class="points-label">积分：</span>
            <span class="points-value">{{ memberPoints }}</span> -->
        </div>
        <div class="member-info">
            <div @click="changeMemberShow"  class="info-item">会员资料
            </div>
            <el-divider />
            <div   class="info-item" >学会简介</div>
            <el-divider  />
            <div @click="changeRecordShow();getRecord()" class="info-item">缴费记录</div>
            <el-divider />
            <div @click="closePage" class="info-item">公众号</div>
        </div>
    </div>
    <div id="memberInfo" v-if="!MemberPageShow&&recordShow">
    <div id="subForm">
        <div class="title" >缴费记录</div>
          <el-table :data="result" style="width: 90%;margin: auto" stripe border height="50vh"  align="center" header-align="center" >
            <el-table-column prop="date" label="缴费时间" align="center"  />
            <el-table-column prop="price" label="金额" align="center" />
            <el-table-column prop="id_card" label="身份证号" align="center" />
          </el-table>
          <el-button type="danger" @click="changeRecordShow" size="large" style="margin-top:15px;margin-bottom:15px">返回</el-button>
    </div>
    </div>
</template>
  
<script>
import MembershipCard from "../components/VipCard.vue";
import request from "../request";
import {ref} from "vue"

export default {
    components: {
    MembershipCard,
},
    data() {
        return {
            openId:ref(),
            MemberPageShow:ref(false),
            recordShow:ref(false),
            baseUrl:process.env.NODE_ENV === "production" ? "" : "http://cenziliweichat.natapp4.cc",
            memberPoints: 0,
            name: ref(),
            work_place: ref(),
            validity: ref(),
            position: ref(),
            positionLabel:ref(false),
            cardNumber: ref(""),
            validDate:ref(),
            address:ref(),
            result : [],
        };
    },
    created(){
        if (window.localStorage.getItem('openId')) {//如果缓存中的openid未定义，则清除缓存
            var firstOpenId = window.localStorage.getItem('openId')
            if (firstOpenId == undefined || firstOpenId == null || firstOpenId == '' || firstOpenId == "undefined") {
                window.localStorage.clear()
            }
        }
        if (!window.localStorage.getItem('openId')) { 
            this.getCode()
        }else{
          this.openId=window.localStorage.getItem('openId')
          this.judgeState()
        }

    },
    mounted(){
       this.MemberPageShow=false
    },
    methods: {
      getCode () { //获取用户的code
          this.code = ''
          var local = window.location.href // 获取页面url
          var appid = 'wxc6124cb8ddfa917a'
          // wxc6124cb8ddfa917a
          this.code = this.getUrlCode().code // 截取code
          
          if (this.code == null || this.code === ''||this.code == undefined) { // 如果没有code，则去请求
            window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(local)}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`//重定向
          }else{
            request.post(this.baseUrl+"/member/getMemberByCode", {code:this.code},{}).then(res => {
              window.localStorage.setItem('openId',res.openId)
              this.openId = res.openId
              this.judgeState()
            }
            )
          }
        },
      getUrlCode() { // 截取url中的code方法
        var url = location.search
        this.winUrl = url
        var theRequest = new Object()
        if (url.indexOf("?") != -1) {
          var str = url.substring(1)
          var strs = str.split("&")
          for (var i = 0; i < strs.length; i++) { theRequest[strs[i].split("=")[0]] = (strs[i].split("=")[1]) }
        } 
        return theRequest
      },
      closePage() {
        if (typeof WeixinJSBridge == "undefined") {
          if (document.addEventListener) {
            document.addEventListener('WeixinJSBridgeReady', this.wxClosePage, false);
          } else if (document.attachEvent) {
            document.attachEvent('WeixinJSBridgeReady', this.wxClosePage);
            document.attachEvent('onWeixinJSBridgeReady', this.wxClosePage);
          }
        } else {
          this.wxClosePage();
        }
      },
      wxClosePage() {
        WeixinJSBridge.call('closeWindow');
      },
      changeMemberShow(){
          if(this.MemberPageShow){
              this.MemberPageShow=false
          }else{
              this.MemberPageShow=true
          }
      },
      changeRecordShow(){
        if(this.recordShow){
              this.recordShow=false
          }else{
              this.recordShow=true
          }
      },
      getRecord(){
        request.get(this.baseUrl+"/order/getOrderByOpenId?openId="+this.openId).then(res => {
          if(res.result.length>0){
            for(let i=0;i<res.result.length>0;i++){
                if(res.result[i].price!=null&&res.result[i].price!=""){
                  res.result[i].price = String(parseInt(res.result[i].price)/100)+"元"
                }
                var date2 = res.result[i].date
                var recordDate =  new Date(date2)
                res.result[i].date = recordDate.getFullYear()+"年"+(recordDate.getMonth()+1)+"月"+recordDate.getDate()+"日"+ recordDate.getHours() + ":" +recordDate.getMinutes() + ":" + recordDate.getSeconds();   
            }
            this.result=res.result    
          }
        })
      },
      judgeState(){
        if (this.openId) {
            request.get(this.baseUrl+"/order/getOrderByOpenId?openId="+this.openId).then(res1 => {
                if(res1.result.length==0){
                  this.$message({
                      type: "error",
                      message: "未支付会员费，请先在登录页面支付"
                  }) 
                  setTimeout(() => {
                    this.closePage()
                  }, 2000);
                  
                }else if(res1.result.length>0){
                    this.cardNumber = "No. 2023000"+ res1.result[0].row_id
                    var date2 = res1.result[0].date
                    var d = new Date(date2);
                    this.validDate=(d.getFullYear()) +1+ '-' + (d.getMonth() + 1 > 9 ? d.getMonth() + 1 : '0' + (d.getMonth() + 1)) + '-' + (d.getDate() > 9 ? d.getDate() : '0' + d.getDate())
                    request.post(this.baseUrl+"/member/getMemberByOpenId", {openId:this.openId},{}).then(res => {
                        if(res.userInfo.length>0){
                            this.name= res.userInfo[0].person_name,
                            this.work_place=res.userInfo[0].organization
                            this.address = res.userInfo[0].address
                            this.position = res.userInfo[0].duty
                            if(this.position == ""||this.position == null){
                              this.positionLabel = false
                            }else{
                              this.positionLabel = true
                            }
                        }
                    })   
                }else{
                    this.closePage()
                }
            })
        }
      },
    }
};
</script>

<style scoped>
.MembershipCard {
    display: flex;
    justify-content: center;
    /* 水平居中 */
    /* 设置容器高度，使其占据整个视口高度 */
}

.card-points {
    margin-top: 20px;
    text-align: center;
    /* 水平居中 */
}

.points-label {
    font-size: 16px;
    margin-right: 5px;
}

.points-value {
    font-size: 18px;
    
    font-weight: bold;
}

.member-info {
    margin-top: 60px;
    margin-left: 20px;
}

.info-item {
    margin-bottom: 10px;
    /* 添加一些垂直间距 */
    font-size: 14px;
    color: #333;
    text-decoration: none;
    display: block;
    cursor: pointer;
    /* 将链接显示为块级元素，以实现左对齐 */
}

.info-item:hover {
    color: #007bff;
}

.el-descriptions {
  margin-top: 10px;
  text-align: center;
  line-height: 40px;
}

#memberInfo {
  position: relative;
  width: 100vw;
  height: 100%;
  background-image: url('../assets/641.jpg');
  background-repeat: no-repeat;
  overflow: hidden;
  background-size: cover;
  background-position: center top;
  text-align: center;

}

#subForm {
  width: 350px;
  min-height: 60vh;
  margin: 1vh auto;
  top: 0;
  background: rgba(255, 250, 240, .5);
  box-sizing: border-box;
  border-radius: 15px;
  box-shadow: 0px 15px 25px rgba(0, 0, 0, .5);
  opacity: 0.75;
  text-align: center;
  }
.title{
    font-size: 25px;
    line-height: 50px;
}
</style>
  