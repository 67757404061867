<template>
  <router-link to="/"></router-link>
  <router-view></router-view>
</template>

<script>
const debounce = (fn, delay) => {
  let timer = null;
  return function () {
    let context = this;
    let args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  }
}

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver{
  constructor(callback) {
    callback = debounce(callback, 16);
    super(callback);
  }
}


</script>

<style>
html,
body,
#app {
  padding: 0px;
  margin: 0px;
  height: 100%;
  width: 100%;
}
</style>