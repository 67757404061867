<template>
  
  <div id="login">
    <el-scrollbar max-height=100% >
    <h1 class="name">海南省生物医学工程学会</h1>
    <div id="subForm" v-if="showAll">
      <div style="text-align: center;color: black;font-weight: bold;font-size: 22px;margin: 10px auto;padding-top: 2vh;">
        会员注册</div>
      <div class="type">
        <el-radio-group v-model="typeValue" size="large" @change="typeChange">
          <el-radio-button label="单位会员"  :disabled="true"></el-radio-button>
          <el-radio-button label="个人会员"></el-radio-button>
        </el-radio-group>
      </div>

      <!-- <el-form v-show="!isShow" :model="formUnit" label-width="110px" ref="formUnit" :rules="rulesUnit" size="default">
        <el-form-item label="单位名称" prop="unitname">
          <el-input style="width: 85%" placeholder="请输入单位名称" v-model="formUnit.unitname" />
        </el-form-item>
        <el-form-item label="法定代表人" prop="representative">
          <el-input style="width: 85%" placeholder="请输入法定代表人" v-model="formUnit.representative" />
        </el-form-item>
        <el-form-item label="联系人姓名" prop="contactname">
          <el-input style="width: 85%" placeholder="请输入联系人姓名" v-model="formUnit.contactname" />
        </el-form-item>
        <el-form-item label="联系人职务" prop="contactduty">
          <el-input style="width: 85%" placeholder="请输入联系人职务" v-model="formUnit.contactduty" />
        </el-form-item>
        <el-form-item label="注册手机号" prop="telephone">
          <el-input style="width: 85%" placeholder="请输入注册手机号" v-model="formUnit.telephone" />
        </el-form-item>
        <el-form-item label="注册密码" prop="password">
          <el-input style="width: 85%" placeholder="请输入注册密码" v-model="formUnit.password" show-password />
        </el-form-item>
        <el-form-item label="确认密码" prop="confirm">
          <el-input style="width: 85%" v-model="formUnit.confirm" show-password placeholder="请再次输入密码"></el-input>
        </el-form-item>
        <el-form-item label="通讯地址" prop="address">
          <el-input style="width: 85%" type="textarea" placeholder="请输入通讯地址" v-model="formUnit.address" />
        </el-form-item>
        <div style="padding-bottom: 4vh;padding-top: 2vh;">
          <el-button type="danger" @click="registerUnit">点击注册</el-button>
          <el-button type="primary" @click="login">已有账号，跳转登录</el-button>
        </div>
      </el-form> -->
      <el-form   v-if="examine=='审核前'"  v-show="isShow" :model="form" label-width="110px" ref="form" :rules="rules" size="default">
        <el-form-item label="姓名" prop="person_name">
          <el-input style="width: 85%" placeholder="请输入姓名" v-model="form.person_name" />
        </el-form-item>
        <el-form-item label="身份证号" prop="idCard_number">
          <el-input style="width: 85%" placeholder="请输入身份证号" v-model="form.idCard_number" />
        </el-form-item>
        <el-form-item label="政治面貌" prop="political_outlook">
            <el-select v-model="value" placeholder="请选择" style="width: 85%">
              <el-option v-for="item in options" :key="item.value"  :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        <el-form-item label="工作单位" prop="organization">
          <el-input style="width: 85%" placeholder="请输入工作单位及职务" v-model="form.organization" />
        </el-form-item>
        <el-form-item label="注册手机号" prop="phone_number">
          <el-input style="width: 85%" placeholder="请输入注册手机号" v-model="form.phone_number" type="tel" />
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input style="width: 85%" placeholder="请输入密码" v-model="form.password" show-password />
        </el-form-item>
        <el-form-item label="确认密码" prop="confirm">
          <el-input style="width: 85%" v-model="form.confirm" show-password placeholder="请再次输入密码"></el-input>
        </el-form-item>
        <el-form-item label="通讯地址" prop="address">
          <el-input style="width: 85%" type="textarea" placeholder="请输入通讯地址" v-model="form.address" />
        </el-form-item>
        
      </el-form>
      <div  v-if="examine!='审核前'" class="examineBox">
        <div>姓名：{{userName}}</div>
        <div>身份证号：{{id}}</div>
        <el-icon class="acitonButton"><Clock /></el-icon>
        <div  v-if="examine =='审核成功'"  style="color:green;font-size:20px;line-height:40px;font-weight: bold;">&gt;&gt;&gt;{{this.examine}}&gt;&gt;&gt;</div>
        <div  v-else-if="examine =='审核失败，请联系工作人员'"  style="color:red;font-size:20px;line-height:40px;font-weight: bold;">&gt;&gt;&gt;{{this.examine}}&gt;&gt;&gt;</div>
        <div  v-else  style="color:#1798a8;font-size:20px;line-height:40px;font-weight: bold;">&gt;&gt;&gt;{{this.examine}}&gt;&gt;&gt;</div>
      </div>
      <div v-if="examine=='审核前'" style="padding-bottom: 4vh;padding-top: 2vh;">
          <el-button type="primary" @click="register" size="large">点击注册</el-button>
          <el-button type="primary" @click="login" size="large">已有账号，跳转登录</el-button>
      </div>
      
    </div>
  </el-scrollbar>
  </div>

</template>
 
<script>
import { ref } from 'vue';
import {Clock} from "@element-plus/icons-vue";
import request from "../request";
export default {
  name: "RegisterTest",
  components: {
        Clock,
        },
  data() {
    var poValidator = (rule, value, callback) => {
      if (!this.value.length) {
        callback(new Error('请选择政治面貌'))
      } else {
        callback()
      }
    }
    return {
      showAll:ref(false),
      loading: ref(true),
      openId:ref(),
      baseUrl:process.env.NODE_ENV === "production" ? "" : "http://cenziliweichat.natapp4.cc",
      typeValue: '个人会员',
      isShow: true,
      form: {},
      examine:ref("审核前"),
      userName:ref(),
      id:ref(),
      // formUnit:{},
      rules: {
        person_name: [
          { required: true, message: '请输入姓名', trigger: 'blur'},
          { min: 2, max: 5, message: '长度在 2 到 5 个字符', trigger: 'blur' }
        ],
        idCard_number: [
          { required: true, message: '请输入身份证号', trigger: 'blur' },
          { min: 18, max: 18, message: '请输入正确的身份证号', trigger: 'blur' }
        ],
        political_outlook:[
          { required: true, validator: poValidator, trigger: 'blur' },
        ],
        organization:[
          { required: true, message: '请填写工作单位及职务', trigger: 'blur' },
        ],
        phone_number:[
          { required: true, message: '请输入注册手机号', trigger: 'blur' },
          { min: 11, max: 11, message: '请输入正确的手机号', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },

          { min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: 'blur' }
        ],
        confirm: [
          { required: true, message: '请再次输入密码', trigger: 'blur' }
        ],
        address: [
          { required: true, message: '请输入地址', trigger: 'blur' },
          { min: 2, message: '长度要大于2个字符', trigger: 'blur' }
        ]
      },
      // rulesUnit: {
      //   unitname: [
      //     { required: true, message: '请输入单位名称', trigger: 'blur' },
      //   ],
      //   representative:[
      //     { required: true, message: '请输入法定代表人', trigger: 'blur' },
      //     { min: 2, max: 5, message: '长度在 2 到 5 个字符', trigger: 'blur' }
      //   ],
      //   contactname: [
      //     { required: true, message: '请输入联系人', trigger: 'blur' },
      //     { min: 2, max: 5, message: '长度在 2 到 5 个字符', trigger: 'blur' }
      //   ],
      //   contactduty:[
      //     { required: true, message: '请输入联系人职务', trigger: 'blur' },
      //   ],
      //   telephone: [
      //     { required: true, message: '请输入联系人手机号', trigger: 'blur' },
      //     { min: 11, max: 11, message: '长度为11个数字', trigger: 'blur' }
      //   ],
      //   password: [
      //     { required: true, message: '请输入密码', trigger: 'blur' },
      //     { min: 6, max: 11, message: '长度在 6 到 11 个字符', trigger: 'blur' }
      //   ],
      //   confirm: [
      //     { required: true, message: '请再次输入密码', trigger: 'blur' }
      //   ],
      //   address: [
      //     { required: true, message: '请输入地址', trigger: 'blur' },
      //     { min: 2, message: '长度要大于2个字符', trigger: 'blur' }
      //   ]
      // },
      options: [{
          value: '中共党员',
          label: '中共党员'
        }, {
          value: '中共预备党员',
          label: '中共预备党员'
        }, {
          value: '共青团员',
          label: '共青团员'
        }, {
          value: '其他党派人士',
          label: '其他党派人士'
        }, {
          value: '群众',
          label: '群众'
        }],
        value: '',
    }
  },
  mounted() {
    this.examine="审核前"
    if(window.localStorage.getItem('openId')){//如果缓存中的openid未定义，则清除缓存
      var firstOpenId = window.localStorage.getItem('openId')
      if(firstOpenId==undefined||firstOpenId==null||firstOpenId==''||firstOpenId=="undefined"){
        window.localStorage.clear()
      }
    }
    if(!window.localStorage.getItem('openId')){ // 如果缓存localStorage中没有微信openId，则需用code去后台获取
      this.getCode();
    }
    else {
      this.judgeRegisterByOpenId();//是否跳转支付页
    }
  },
  methods: {
    register() {
      if (this.form.password !== this.form.confirm) {
        this.$message({
          type: "error",
          message: "两次输入密码不一致"
        })
        return
      }
      this.userName = this.form.person_name
      this.id = this.form.idCard_number
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.form.political_outlook=this.value
          var url = ''
          var params = {}
          if (!window.localStorage.getItem('openId')){
            url = this.baseUrl+"/member/saveMemberByCode"//注册时没有openid传递code，后端获取openid并存储后返回
            params={code:this.code}

          }else{
            url = this.baseUrl+"/member/saveMemberByOpenId"//缓存中有openid  直接传递给后端
            // alert(window.localStorage.getItem('openId'))
            params={openId:window.localStorage.getItem('openId')}
          }
          request.post(url, this.form,{params:params}).then(res => {
            window.localStorage.setItem('openId',res.openId)
            if (res.MSG === '成功插入1名用户') {
              this.$message({
                type: "success",
                message: "提交成功，等待审核"
              })
              this.examine="审核中"//审核中的状态
            } else {
              this.$message({
                type: "error",
                message: res.MSG
              })

            }
          })
        } else {
          this.$message({
            type: "error",
            message: "请将信息输入完毕"
          })
        }
      })

    },
    login() {
      this.$router.push("/LoginPage")
    },
    
    getCode () { //获取用户的code
      this.code = ''
      var local = window.location.href // 获取页面url
      var appid = 'wxc6124cb8ddfa917a'
      // wxc6124cb8ddfa917a
      this.code = this.getUrlCode().code // 截取code
      if (this.code == null || this.code === ''||this.code == undefined) { // 如果没有code，则去请求
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(local)}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`//重定向
      }else{
        this.getOpenId(this.code)//此时已经获得到code，用code判断是否注册
      }
    },
    getUrlCode() { // 截取url中的code方法
      var url = location.search
      this.winUrl = url
      var theRequest = new Object()
      if (url.indexOf("?") != -1) {
        var str = url.substring(1)
        var strs = str.split("&")
        for (var i = 0; i < strs.length; i++) { theRequest[strs[i].split("=")[0]] = (strs[i].split("=")[1]) }
      } 
      return theRequest
    },
    getOpenId(code){//通过code查询改用是否已经注册，注册则跳转支付页
      request.post(this.baseUrl+"/member/getMemberByCode", {code:code},{}).then(res => {
        window.localStorage.setItem('openId',res.openId)
        this.judgeRegisterByOpenId();
      })
    },
    judgeRegisterByOpenId(){//通过openID查询该用户是否已经注册，注册则跳转支付页
      var openId = window.localStorage.getItem('openId')
      request.post(this.baseUrl+"/member/getMemberByOpenId", {openId:openId},{}).then(res => {
        if(res.data==="YES"){
          this.userName=res.userInfo[0].person_name
          this.id=res.userInfo[0].idCard_number
          if(res.userInfo[0].status=="审核成功"){
              this.examine = "审核成功"
            }
          else if(res.userInfo[0].status=="未审核"){
            this.examine = "审核中" 
            this.show=true           
          }else{
            this.examine = "审核失败，请联系工作人员"
            this.show=true
          }
        }
        this.showAll=true
      })      
    },
    typeChange(val) {
      if (val == "单位会员") {
        this.isShow = true
      } else {
        this.isShow = false
      }
    },
    //注册页跳转支付页
    // judgeValidity(){
    //   this.openId=window.localStorage.getItem("openId")
    //   request.get(this.baseUrl+"/order/getOrderByOpenId?openId="+this.openId).then(res => {
    //     if(res.result.length>0){//有支付成功的最近的一条记录，判断是否满一年
    //         var date2 = res.result[0].date
    //         var m = parseInt(new Date().getTime() - new Date(date2).getTime()) / (1000 * 60 * 60 * 24)
    //         if(m<365){
    //           //不满一年，无需支付
    //           this.$router.push("/PaySuccess")
    //         }else{
    //           //满一年   需要重新支付
    //           this.$router.push({
    //             name:"PayPage",
    //             state: {
    //               params: {
    //                 openId: this.openId,
    //               }
    //             }})
    //         }
            
    //     }else{
    //       this.judgeRegisterByOpenId() //没有记录去判断是否注册
    //     }
    //   })
    // },
    
  }
}
</script>
 
<style scoped>
.name {
  font-family: 'Heiti';
  font-size: 25px;
  color: #1C1D22;
  text-align: center;
  padding-top: 5vh;
}

#login {
  position: relative;
  width: 100vw;
  height: 100%;
  background-image: url('../assets/641.jpg');
  background-repeat: no-repeat;
  overflow: hidden;
  background-size: cover;
  background-position: center top;
  min-width: 380px;
  background-attachment: fixed

}

#subForm {
  /* position: absolute; */
  /*width: 500px;*/
  /*min-height: 300px;*/
  width: 350px;
  min-height: 55vh;
  margin: 1vh auto;
  top: 0;
  background: rgba(255, 250, 240, .5);
  box-sizing: border-box;
  border-radius: 30px;
  box-shadow: 0px 15px 25px rgba(0, 0, 0, .5);
  opacity: 0.75;
  text-align: center;
}
.type{
  margin-bottom: 1vh;
}
.el-form-item{
    margin-bottom: 20px;
}
.el-button{
  font-size:medium
}
.acitonButton{
    border: none;
    color: darkcyan;
    font-size: 120px;
    margin-top: 10px;
  }
.examineBox{
  line-height: 30px;
  font-size: 18px;

}
</style>