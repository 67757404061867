<template>
  <div id="login">
    <h1 class="name">海南省生物医学工程学会</h1>
    <div id="subForm">
      <div style="text-align: center;color: black;font-weight: bold;font-size: 22px;margin: 25px auto;padding-top: 2vh;">管理员登录</div>
      <el-form :model="form" label-width="110px" ref="form" :rules="rules" size="large">
        <el-form-item label="身份证号" prop="idCard_number">
          <el-input style="width: 85%" placeholder="请输入账号" ref="getUser" v-model="form.idCard_number" />
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input style="width: 85%" placeholder="请输入密码" v-model="form.password" show-password />
        </el-form-item>
        <el-form-item style="margin-top: 30px;margin-left: 10px">
         
        </el-form-item>
        <el-button type="primary"  @click="login">点击登录</el-button>
      </el-form>
    </div>
  </div>
</template>
  
<script>
import {ref} from 'vue';
 
 export default {
   name: "LoginTest",
   data() {
     return {
      result:[],
      openId: ref(),
      baseUrl: process.env.NODE_ENV === "production" ? "" : "http://cenziliweichat.natapp4.cc",
      form: {},
      userId: ref(),
      password: ref(),
      rules: {
        idCard_number: [
          { required: true, message: '请输入身份证号', trigger: 'blur' },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 11, message: '长度在 6 到 11 个字符', trigger: 'blur' }
        ],
      },
     }
   },
   mounted(){},

   methods: {
     login() {
      this.userId = this.form.idCard_number
      this.password = this.form.password
      if(this.userId=="admin"&&this.password=="123456"){
        this.$router.push({
          name:"ExaminePage",
          state:{
            params:{
              user:this.userId,
              password:this.password,
            }
          }
      })}
    },
}}
</script>
  
<style scoped lang="scss">
.name{
    font-family: 'Heiti';
    font-size: 25px;
    color: #1C1D22;
    text-align: center;
    padding-top: 12vh;
  }
#login {
  position:relative;
  width: 100vw;
  height: 100%;
  background-image: url('../assets/641.jpg');
  background-repeat: no-repeat;
  overflow: hidden;
  background-size:cover;
  background-position: center top;

}
#subForm {
  /* position: absolute; */
  /*width: 500px;*/
  /*min-height: 300px;*/
  width: 350px;
  min-height:45vh;
  margin:1vh auto;
  top:0;
  background: rgba(255,250,240,.5);
  box-sizing:border-box;
  border-radius: 30px;
  box-shadow: 0px 15px 25px rgba(0,0,0,.5);
  opacity: 0.75;
  text-align: center;
}
.login{
  font-family: 'Heiti';
  font-size: 15px;
  color: #0e0aea;
  text-align: center;
  padding-top: 5vh;
  padding-bottom: 5vh;
  text-decoration:underline
}
  
  </style>